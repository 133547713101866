import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import CarouselComponent from "./CarouselComponent"; // Import your Carousel component
import { IDoc, IUserData } from "../models/models";
import NoDocsFound from "./NoDocsFound";
import React, { useEffect, useState } from "react";
import Top20 from "./Top20";
import VideoCardSkeleton from "./VideoCardSkeleton";

const SkeletonGrid: React.FC = () => {
  const [docsList, setDocList] = useState<string[]>([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "6px",
        backgroundColor: "transparent",
      }}
    >
      <Grid
        container
        rowSpacing={8}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {docsList.map((doc, index) => (
          <React.Fragment key={index}>
            <Grid xs={12} sm={6} md={4} lg={3}>
              <VideoCardSkeleton />
            </Grid>
            {/* Insert CarouselComponent after the 4th item */}
            {index === 3 && (
              <Grid xs={12}>
                <CarouselComponent />
              </Grid>
            )}
            {index === 7 && (
              <Grid xs={12}>
                <Top20 />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default SkeletonGrid;
