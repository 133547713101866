export function getRandomLink(): string {
  //monetag
  const links: string[] = [
    "https://saikaithee.net/4/8583539",
    "https://saikaithee.net/4/8583536",
    "https://saikaithee.net/4/8583532",
    "https://joohugreene.net/4/9007070",
    "https://joohugreene.net/4/9007072",
    "https://whoushex.top/4/8646256",
    "https://whoushex.top/4/8646255",
    "https://whoushex.top/4/8646258",
    "https://whoushex.top/4/8583511",
    "https://whoushex.top/4/8585306",
    "https://whoushex.top/4/8608917",
    "https://whoushex.top/4/8646254",
    "https://whoushex.top/4/8646257",
    "https://whoushex.top/4/8583518",
    "https://whoushex.top/4/8608915",
  ];
  //adsterra
  // const links: string[] = [
  //   "https://www.effectiveratecpm.com/ztr9b1cq?key=48ef5480eff0b5b1ccf9c18a1efbf622",
  //   "https://www.effectiveratecpm.com/cybv7544n?key=374595c78decad452099560f0ec5dd43",
  //   "https://www.effectiveratecpm.com/de82nndtz5?key=19c1fa727a7664c85000f26ccd9fd521",
  //   "https://www.effectiveratecpm.com/rignbc7t0?key=d2922e0cf1941435ad52f307bf2c0652",
  //   "https://www.effectiveratecpm.com/amyqe515?key=fe08538ea16276db7ad2461d4467e9ea",
  //   "https://www.effectiveratecpm.com/aiv5mc2khs?key=3082f16463fad8199d36023e30a67b44",
  //   "https://www.effectiveratecpm.com/abjyn9e5?key=ba0dae0f5a27cbdbd8220f7e8e479196",
  //   "https://www.effectiveratecpm.com/e1tc5geza?key=35ad9410f6bbe85b56a3a668fb44e71e",
  //   "https://www.effectiveratecpm.com/gdskr2id?key=3687c27e0985df79e7b6dc0c11894f39",
  //   "https://www.effectiveratecpm.com/tcdwkz0zh?key=b34c02ea18770d80ea1e3f01d6b10598",
  // ];

  // const links = [
  //   "https://bleak-power.com/bj3aVO0pP.3wpwvoblmOVAJnZoD/0o2/MdTUYS3gOVDbENxvL/TLYHxmNAj-ck4zMmTpIV",
  //   "https://bleak-power.com/b.3ZVM0IPo3lpavJbPm/V/J/ZuDI0F2qMvTpY/3WOODbAIzXLjTeYdxANYjJcF4/MWDvQ_",
  //   "https://bleak-power.com/bs3UV.0ZPA3YpvvcbcmjVpJqZVD/0/2PMmTQYv3bNLzbk/1XLiT/Y/xzNtj/cZ3/OoTPYR",
  //   "https://bleak-power.com/bW3pVO0.PA3MpcvPbbmJV/J_Z/Dx0/2UM/TkYn3FNKzmg/3TLlTqYWx/NHjMck3mO/DegW",
  //   "https://bleak-power.com/b.3CV/0wPI3Jpfv/b_m/VrJxZlDZ0-2rMfTQYX3hNbzfcZ5QLGTNYdxLNtjhcN3RO/DnAr",
  // ];

  const randomIndex = Math.floor(Math.random() * links.length);
  return links[randomIndex];
}
