import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";

const VideoCardSkeleton = () => {
  //let videoCardHeight = alignment ? 380 : 256;

  return (
    <Card
      sx={{
        maxWidth: 384,
        height: 256,
        backgroundColor: "#777777",
        position: "relative",
      }}
    >
      <Skeleton variant="rectangular" width="100%" height={160} />
      <CardContent>
        <Skeleton variant="text" width="80%" height={24} />
        <Skeleton variant="text" width="60%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
      </CardContent>
      <CardActions>
        <div style={{ position: "absolute", bottom: 10, right: 10 }}>
          <IconButton disabled>
            <FavoriteIcon sx={{ color: "lightgrey" }} />
          </IconButton>
        </div>
      </CardActions>
    </Card>
  );
};

export default VideoCardSkeleton;
