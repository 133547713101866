import React, { useState, useEffect } from "react";
import { checkIfUserIsLogged, getData } from "../api/getData";
import DocsGrid from "../components/DocsGrid";
import { IDoc, IUserData } from "../models/models";
import SortAndSearchBar from "../components/SortAndSearchBar";
import { sortDocsBy } from "../utlis/sortDocs-helper";
import Footer from "../components/Footer";
import { Container, Alert, Typography } from "@mui/material";
import Loading from "../components/Loading";
import PaginatedContent from "../components/PaginatedContent";

export interface IHomepage {
  docList: IDoc[];
  setDocList: (docList: IDoc[]) => void;
  setNumberOfDocs: (numberOfDocs: number) => void;
  typeOfDocs: string;
  page: number;
  setPage: (page: number) => void;
  setUserData: (userData: IUserData) => void;
  userData: IUserData;
}

const Homepage: React.FC<IHomepage> = ({
  docList,
  setDocList,
  setNumberOfDocs,
  typeOfDocs,
  page,
  setPage,
  setUserData,
  userData,
}) => {
  const [filteredDocs, setFilteredDocs] = useState<IDoc[]>([]);
  const [filterBy, setFilterBy] = useState<string | null>("");
  const [sortBy, setSortBy] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [alignment, setAlignment] = React.useState(false);
  const [editorChoice, setEditorChoice] = React.useState<boolean>(true);
  const [docsLoaded, setDocsLoaded] = React.useState(false);
  const itemsPerPage: number = 64;

  const getAllDocs = async () => {
    try {
      setIsLoading(true);

      // Check if user is logged in
      if (localStorage.getItem("userEmail")) {
        const userData: IUserData = await checkIfUserIsLogged(
          localStorage.getItem("userEmail")
        );
        if (userData?.isLogged) {
          setUserData({
            isLogged: true,
            email: userData?.email,
            favourites: userData?.favourites,
          });
        }
      }

      // Fetch only the first 64 items initially
      const data = await getData(typeOfDocs);
      if (!data) {
        setRequestError(true);
        return;
      }

      let sortedData = data.sort(
        (a: IDoc, b: IDoc) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );

      setDocList(sortedData);
      setFilteredDocs(sortedData);
      setNumberOfDocs(data.length);
      setEditorChoice(true);
      setIsLoading(false);
      setDocsLoaded(true);
    } catch (err) {
      console.log(err);
      setRequestError(true);
    } finally {
      setIsLoading(false);
      setDocsLoaded(true);
    }
  };

  const updateFilteredDocList = () => {
    if (!filterBy || filterBy === "all") {
      setFilteredDocs(docList);

      const sortedDocs = sortDocsBy(docList, sortBy);
      setFilteredDocs(sortedDocs);
      setNumberOfDocs(docList.length);
      window.scrollTo(0, 0);
    } else {
      const filteredDocs = docList.filter((doc) => doc.genre === filterBy);
      setFilteredDocs(filteredDocs);

      const sortedDocs = sortDocsBy(filteredDocs, sortBy);
      setFilteredDocs(sortedDocs);
      setNumberOfDocs(filteredDocs.length);
    }
    //setPage(1);
    setEditorChoice(false);
    window.scrollTo(0, 0);
  };

  const sortFilteredDocs = () => {
    const sortedDocs = sortDocsBy(filteredDocs, sortBy);
    setFilteredDocs(sortedDocs);
    setEditorChoice(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getAllDocs();
  }, [typeOfDocs]);

  useEffect(() => {
    updateFilteredDocList();
  }, [filterBy]);

  useEffect(() => {
    sortFilteredDocs();
  }, [sortBy]);

  if (requestError)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>

        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  return (
    <>
      <div
        style={{
          marginTop: "-44px",
          width: "100%",
          overflowX: "hidden",
          backgroundColor: "#111111",
        }}
      >
        <SortAndSearchBar
          alignment={alignment}
          setAlignment={setAlignment}
          setFilterBy={setFilterBy}
          setSortBy={setSortBy}
          searchTitles={filteredDocs.map((doc: IDoc) => {
            return { title: doc.title, _id: doc._id };
          })}
        />
        <div style={{ height: "38px" }}></div>
        <PaginatedContent
          itemsPerPage={itemsPerPage}
          items={filteredDocs}
          alignment={alignment}
          page={page}
          setPage={setPage}
          editorChoice={editorChoice}
          docsLoaded={docsLoaded}
          typeOfDocs={typeOfDocs}
          userData={userData}
          isLoading={isLoading}
        />
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
