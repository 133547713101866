import React from "react";
import "./VideoCard.css";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { editorsItems } from "../utlis/editorsItems";
import { Link } from "react-router-dom";

// Define custom styles for the carousel items and arrows
const CarouselItem = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 216,
  backgroundColor: "#f5f5f5",
  border: "1px solid #ddd",
  boxSizing: "border-box",
  overflow: "hidden",
});

const CarouselImage = styled("img")({
  width: "100%",
  height: "110%",
  objectFit: "cover",
  borderRadius: "6px",
});

const OverlayText = styled(Typography)({
  position: "absolute",
  top: 8,
  left: 8,
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  padding: "4px 8px",
  borderRadius: "4px",
});

const CarouselComponent: React.FC = () => {
  // Settings for the carousel
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "94%",
        margin: "0 auto",
        paddingTop: "2px",
        marginBottom: "-4px",
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          marginLeft: "10px",
          color: "#fefefe",
          fontSize: "1.6rem",
          marginBottom: "2px",
        }}
      >
        Editor´s montlhy choice
      </Typography>
      <Slider {...settings}>
        {editorsItems.map((item) => (
          <CarouselItem
            key={item.id}
            className="video-card"
            sx={{ borderRadius: "6px", border: "none" }}
          >
            <Link
              to={`/singleDoc/${item.navigationId}`}
              style={{ textDecoration: "none" }}
            >
              <CarouselImage src={item.imageUrl} alt={`Item ${item.id}`} />
            </Link>
          </CarouselItem>
        ))}
      </Slider>
      <div style={{ height: "10px" }}></div>
    </Box>
  );
};

export default CarouselComponent;
