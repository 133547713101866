import React, { useEffect, useState } from "react";
import "./VideoCard.css";
import Slider from "react-slick";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { getData, getTop20api } from "../api/getData";
import { IDoc } from "../models/models";
import { Alert } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoDocsFound from "./NoDocsFound";
import Loading from "./Loading";

const CarouselItem = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 216,
  backgroundColor: "#f5f5f5",
  border: "1px solid #ddd",
  boxSizing: "border-box",
  overflow: "hidden",
});

const CarouselImage = styled("img")({
  width: "100%",
  height: "110%",
  objectFit: "cover",
  borderRadius: "6px",
});

const OverlayText = styled(Typography)({
  position: "absolute",
  top: 8,
  left: 8,
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  padding: "4px 8px",
  borderRadius: "4px",
  fontWeight: "bold",
});

const Top20: React.FC = () => {
  const [requestError, setRequestError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [topList, setTopList] = useState<IDoc[]>([]);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getTopDocs = async () => {
    try {
      setIsLoading(true);
      const data = await getData("docs");
      if (!data) setRequestError(true);
      if (data) {
        let topData = await getTop20api();
        if (topData) {
          let finalTopData: IDoc[] = [];
          for (let i = 0; i < topData.length; i++) {
            finalTopData.push({
              ...data.find((doc: IDoc) => doc._id === topData[i].videoId),
              playCount: topData[i].playCount,
            });
          }
          setTopList(finalTopData);
        } else {
          setTopList(data.slice(0, 20));
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setRequestError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTopDocs();
    window.scrollTo(0, 0);
  }, []);

  if (requestError)
    return (
      <Container sx={{ mt: 4, textAlign: "center" }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {requestError}
        </Alert>
        <Typography variant="body1" color="textSecondary">
          There was an issue fetching the document. Please check your internet
          connection or try again later.
        </Typography>
      </Container>
    );

  if (isLoading) return <Loading />;

  if (topList.length === 0) return <NoDocsFound />;

  return (
    <Box
      sx={{
        width: "94%",
        margin: "0 auto",
        paddingTop: "2px",
        marginBottom: "-4px",
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          marginLeft: "10px",
          color: "#fefefe",
          fontSize: "1.6rem",
          marginBottom: "2px",
        }}
      >
        Top 20
      </Typography>
      <Slider {...settings}>
        {topList.map((item, index) => (
          <Tooltip title={item.title} key={item._id}>
            <CarouselItem
              className="video-card"
              sx={{ borderRadius: "6px", border: "none" }}
            >
              <OverlayText sx={{ fontSize: "26px" }}>{index + 1}</OverlayText>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VisibilityIcon sx={{ fontSize: "20px", marginRight: "4px" }} />
                <Typography variant="body2">{item?.playCount}</Typography>
              </Box>
              <Link
                to={`/singleDoc/${item._id}`}
                style={{ textDecoration: "none" }}
              >
                <CarouselImage src={item.image} alt={`Item ${item._id}`} />
              </Link>
            </CarouselItem>
          </Tooltip>
        ))}
      </Slider>
      <div style={{ height: "10px" }}></div>
    </Box>
  );
};

export default Top20;
