import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import VideoCard from "./VideoCard";
import CarouselComponent from "./CarouselComponent"; // Import your Carousel component
import { IDoc, IUserData } from "../models/models";
import NoDocsFound from "./NoDocsFound";
import React, { useEffect, useState } from "react";
import Top20 from "./Top20";

export interface IDocsGrid {
  docsList: IDoc[];
  alignment: boolean;
  docsLoaded: boolean;
  typeOfDocs: string;
  userData: IUserData;
}

const DocsGrid: React.FC<IDocsGrid> = ({
  docsList,
  alignment,
  docsLoaded,
  typeOfDocs,
  userData,
}) => {
  if (docsList.length === 0 && docsLoaded) return <NoDocsFound />;

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginBottom: "6px",
        backgroundColor: "transparent",
      }}
    >
      <Grid
        container
        rowSpacing={8}
        columnSpacing={2}
        justifyContent="center"
        alignItems="center"
        padding={1}
      >
        {docsList.map((doc, index) => (
          <React.Fragment key={doc._id}>
            <Grid xs={12} sm={6} md={4} lg={3}>
              <VideoCard
                docInfo={doc}
                alignment={alignment}
                typeOfDocs={typeOfDocs}
                userData={userData}
              />
            </Grid>
            {/* Insert CarouselComponent after the 4th item */}
            {index === 3 && typeOfDocs === "docs" && (
              <Grid xs={12}>
                <CarouselComponent />
              </Grid>
            )}
            {index === 7 && typeOfDocs === "docs" && (
              <Grid xs={12}>
                <Top20 />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default DocsGrid;
